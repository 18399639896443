import '../styles/App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        Craft Boutique India        
        <br></br>
        <br></br>
        Coming Soon :) 
      </header>
    </div>
  );
}

export default App;
